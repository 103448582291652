.toggle_wrapper {
  position: relative;
  bottom: 8px;
  margin-left: 30px;

  @media screen and (max-width: 726px) {
    bottom: 0px;
  }

  input {
    position: absolute;
    left: -99em;
    display: none;
  }
}

.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 60px;
  height: 35px;
  background-color: #004be9;
  border-radius: 90px - 6;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 6px;
  left: 6px;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-45deg);

  .crater {
    position: absolute;
    background-color: #e8cda5;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: 100%;
  }

  .crater1 {
    top: 18px;
    left: 10px;
    width: 4px;
    height: 4px;
  }

  .crater2 {
    top: 28px;
    left: 22px;
    width: 6px;
    height: 6px;
  }

  .crater3 {
    top: 10px;
    left: 25px;
    width: 8px;
    height: 8px;
  }
}

.star {
  position: absolute;
  background-color: #ffffff;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border-radius: 50%;
}

.star1 {
  top: 5px;
  left: 42px;
  z-index: 0;
  width: 5px;
  height: 5px;
}

.star2 {
  top: 11px;
  left: 34px;
  z-index: 1;
  width: 4px;
  height: 4px;
}

.star4,
.star5,
.star6 {
  opacity: 0;
  transition: all 300ms 0 cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.star4 {
  top: 16px;
  left: 11px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px, 0, 0);
}

.star5 {
  top: 32px;
  left: 17px;
  z-index: 0;
  width: 3px;
  height: 3px;
  transform: translate3d(3px, 0, 0);
}

.star6 {
  top: 36px;
  left: 28px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px, 0, 0);
}

input:checked {
  + .toggle {
    background: linear-gradient(
      89.79deg,
      rgba(60, 112, 221, 0.1) 8.49%,
      rgba(44, 218, 255, 0.1) 97.1%
    ) !important;

    &:before {
      color: #004be9;
    }

    &:after {
      color: #ffffff;
    }

    .toggle__handler {
      background: linear-gradient(
        89.79deg,
        rgb(0 0 0 / 10%) 8.49%,
        rgb(0 0 0 / 10%) 97.1%
      ) !important;
      transform: translate3d(25px, 0, 0) rotate(125deg);
      box-shadow: inset 0px 8px #2cdaff, inset 0px 4px 0px 0px #999;

      .crater {
        opacity: 1;
        background-color: #2cdaff;
      }
    }

    .star1 {
      width: 0px;
      height: 0px;
    }

    .star2 {
      width: 0px;
      height: 0px;
      background-color: #e8cda5;
      transform: translate3d(-5px, 0, 0);
    }

    .star3 {
      width: 3px;
      height: 3px;
      top: 7px;
      transform: translate3d(-10px, 0, 0);
      background-color: #2cdaff;
    }

    .star4 {
      top: 18px;
      left: 16px;
      opacity: 1;
      background-color: #2cdaff;
    }

    .star5,
    .star6 {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .star4 {
      transition: all 300ms 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    .star5 {
      transition: all 300ms 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    .star6 {
      transition: all 300ms 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
}
