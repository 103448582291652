.main {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: #f8fafe;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  @media screen and (max-width: 726px) {
    top: 17%;
    height: 30%;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
  }
}

.hero svg {
  height: 100%;
  width: 100%;
  transform: scale(1.05);

  @media screen and (max-width: 726px) {
    transform: scale(1.5);
  }
}

.key,
.keyhead {
  transform: scale(0.8);
  transform-origin: 50% 50%;
}

.gear {
  animation: gear-rotate 10s forwards linear infinite;
}

@keyframes gear-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#left path,
#right path {
  fill: #e9eeff;
  opacity: 1;
}

#left path:hover,
#right path:hover {
  opacity: 0.5;
}

.top-left path,
.bottom-left path,
.top-right path,
.bottom-right path {
  fill: #e9eeff;
}

.key-left .key1,
.key-right .key2,
.key-top .key3,
.key-btm .key4 {
  cursor: pointer;
  transition: all 2s ease;
  transform-origin: 960px 540px;
}

.key-left .active {
  transform: translateX(105px);
}

.key-right .active {
  transform: translateX(-105px);
}

.key-top .active {
  transform: translateY(105px);
}

.key-btm .active {
  transform: translateY(-105px);
}

.main {
  transform: rotate(0deg);
  transform-origin: 960px 540px;
  transition: all 2s ease;
}

/*  style="fill:#00FFFF;" */

/* Hero section CSS starts here */
.hero-section {
  position: relative;
  z-index: 6;
  height: inherit;
  display: none;
  transition: all 0.6s ease;
}

.nft-container {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    height: 35vh;
  }
}

.slider {
  height: 750px;
  width: 100vw;
  display: flex;
  perspective: 1000px;
  position: relative;
  align-items: center;
}

.box1 {
  background: url("../assets/carousel/4.png");
  background-size: cover;
  background-position: center center;
}

.box2 {
  background: url("../assets/carousel/3.png");
  background-size: cover;
  background-position: center center;
}

.box3 {
  background: url("../assets/carousel/2.png");
  background-size: cover;
  background-position: center center;
}

.box4 {
  background: url("../assets/carousel/1.png");
  background-size: cover;
  background-position: center center;
}

.box5 {
  background: url("../assets/carousel/8.png");
  background-size: cover;
  background-position: center center;
}

.box6 {
  background: url("../assets/carousel/7.png");
  background-size: cover;
  background-position: center center;
}

.box7 {
  background: url("../assets/carousel/6.png");
  background-size: cover;
  background-position: center center;
}

.box8 {
  background: url("../assets/carousel/5.png");
  background-size: cover;
  background-position: center center;
}

.slider [class*="box"] {
  /*   float: left; */
  overflow: hidden;
  border-radius: 20px;
  transition: all 1s cubic-bezier(0.37, 0, 0.6, 1);
  position: absolute;
}

.slider [class*="box"]:nth-child(7),
.slider [class*="box"]:nth-child(1) {
  width: 100vh;
  height: 75vh;
  transform: scale(0.2) translate(-50%, -50%);
  top: 24%;
  z-index: 1;
  box-shadow: 1px 1px 0.6rem #c0c0c0;

  @media screen and (max-width: 767px) {
    height: 35vh;
    top: 30%;
  }
}

.slider [class*="box"]:nth-child(2),
.slider [class*="box"]:nth-child(6) {
  width: 100vh;
  height: 75vh;
  transform: scale(0.4) translate(-50%, -50%);
  top: 32%;
  z-index: 2;
  box-shadow: 1px 1px 0.6rem #c0c0c0;

  @media screen and (max-width: 767px) {
    height: 35vh;
    top: 33%;
  }
}

.slider [class*="box"]:nth-child(3),
.slider [class*="box"]:nth-child(5) {
  width: 100vh;
  height: 75vh;
  transform: scale(0.6) translate(-50%, -50%);
  top: 38%;
  z-index: 3;
  box-shadow: 1px 1px 0.6rem #c0c0c0;

  @media screen and (max-width: 767px) {
    height: 22vh;
    top: 40%;
  }
}

.slider [class*="box"]:nth-child(4) {
  width: 55vw;
  height: 75vh;
  border-color: #c92026;
  color: #fff;
  transform: scale(1) translate(-50%, -50%);
  top: 52%;
  z-index: 4;
  box-shadow: 1px 1px 0.6rem #c0c0c0;

  @media screen and (max-width: 767px) {
    height: 22vh;
    width: 75vw;
    top: 49%;
  }

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    height: 68vh;
  }
}

.slider [class*="box"]:nth-child(1) {
  left: -13%;

  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.slider [class*="box"]:nth-child(2) {
  left: -4%;

  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.slider [class*="box"]:nth-child(3) {
  left: 10%;

  @media screen and (max-width: 767px) {
    left: 6%;
    width: 50%;
  }

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    left: 14%;
  }
}

.slider [class*="box"]:nth-child(4) {
  left: 50%;

  @media screen and (max-width: 767px) {
    left: 45%;
  }

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    left: 52%;
  }
}

.slider [class*="box"]:nth-child(5) {
  left: 69%;

  @media screen and (max-width: 767px) {
    left: 64%;
    width: 50%;
  }

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    left: 67%;
  }
}

.slider [class*="box"]:nth-child(6) {
  left: 72%;

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    left: 71%;
  }
}

.slider [class*="box"]:nth-child(7) {
  left: 100%;
}

.slider .firstSlide {
  -webkit-animation: firstChild 1s;
  animation: firstChild 1s;
}

/*Animation for buyers landing page slider*/
@-webkit-keyframes firstChild {
  0% {
    left: 100%;
    transform: scale(0.2) translate(-50%, -50%);
  }

  100% {
    top: 20%;
    left: -13%;
    transform: scale(0.2) translate(-50%, -50%);
  }
}

@keyframes firstChild {
  0% {
    left: 100%;
    transform: scale(0.2) translate(-50%, -50%);
  }

  100% {
    left: -13%;
    transform: scale(0.2) translate(-50%, -50%);
  }
}

.leftfill,
.rightfill {
  fill: #e9eeff;
  stroke: #004be9;
  stroke-miterlimit: 10;
}

.enableBgNew {
  enable-background: new;
}

.borderShadow {
  border: 1px solid #e3e3e3;
  border-radius: 20px;

  @media screen and (max-width: 726px) {
    border: 0;
  }
}

.slider-text {
  position: absolute;
  top: 82%;

  @media screen and (max-width: 726px) {
    top: 28%;
  }

  @media only screen and (min-height: 768px) and (min-width: 1366px) {
    top: 77%;
  }
}
