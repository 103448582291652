.section-1 {
  z-index: 1;
}

.illu-section {
  margin: auto;

  @media screen and (max-width: 726px) {
    position: relative;
    bottom: 150px;
  }
}

.svg-container {
  text-align: -webkit-center !important;
  text-align: center;

  .hero-svg {
    height: 500px;
    width: 500px;
    overflow: visible;

    @media screen and (max-width: 726px) {
      height: 375px;
      width: 375px;
    }
  }
}

.hero-svg {
  height: 500px;
  width: 500px;
  overflow: visible;

  @media screen and (max-width: 726px) {
    height: 375px;
    width: 375px;
  }
}

.hero-svg-spin {
  height: 550px;
  width: 900px;
  overflow: visible;

  @media screen and (max-width: 726px) {
    // height: 350px;
    // width: 350px;
  }
}

@keyframes tornado {
  from {
    stroke-dashoffset: 200;
    stroke-dasharray: 1, 10;
  }

  to {
    stroke-dashoffset: 0;
    stroke-dasharray: 1, 10;
  }
}

@keyframes tornado2 {
  from {
    stroke-dashoffset: 0;
    stroke-dasharray: 1, 10;
  }

  to {
    stroke-dashoffset: 200;
    stroke-dasharray: 1, 10;
  }
}

@keyframes data {
  from {
    stroke-dashoffset: 200;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes data1 {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 100;
  }
}

@keyframes uploading {
  0% {
    transform: translate(3px, -2px);
  }

  50% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(3px, -2px);
  }
}

.flex-display {
  display: flex;
}

.pin-spacer {
  /*   max-height:1500px; */
}

.circles {
  z-index: 8;
}

// .illu-section svg {
//     height: 500px;
//     overflow: visible;

//     @media screen and (max-width: 767px) {
//         height: 125px;
//     }
// }

ellipse,
.connector-lines line,
.connections path {
  stroke-dashoffset: 200;
  stroke-dasharray: 3, 10;
  animation: tornado 10s forwards linear infinite;
}

.circle-top,
.circle-btm {
  stroke-dashoffset: 200;
  stroke-dasharray: 3, 10;
  animation: tornado2 10s forwards linear infinite;
}

.circle {
  transform: scale(1);
}

.transfer {
  stroke-dashoffset: 200;
  stroke-dasharray: 200, 10;
  animation: data 10s forwards linear infinite;
  filter: blur(1px);
}

.goup {
  stroke-dashoffset: 100;
  stroke-dasharray: 50, 50;
  animation: data1 2s forwards linear infinite;
}

.pulse {
  animation: uploading 2s forwards linear infinite;
}

.shadow,
.circle-btm {
  filter: blur(1px);
}

.enableBg {
  enable-background: "new 0 0 512 512";
}

.textAlignWebkitCenter {
  text-align: -webkit-center;
}

.sec3 {
  // text-align: center;
  transform: translateY(-280px);

  @media screen and (max-width: 767px) {
    transform: translateY(-300px);
  }
}

.sec4-content {
  opacity: 0;
  transform: translateY(-150px);
  // display: none;
}

.sec3-content {
  @media screen and (max-width: 767px) {
    transform: translateY(-10px);
  }
}

.svg-ml-mobile {
  width: 1000px;

  @media screen and (max-width: 767px) {
    width: 600px;
    position: relative;
    left: 25px;
    top: -50px;
  }
}

.mt-25 {
  margin-top: 25px;

  @media screen and (max-width: 767px) {
    margin-top: 25px;
  }
}
