@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Roboto+Mono:wght@400;500;600&family=Syne:wght@400;500;600;700;800&family=Big+Shoulders+Display:wght@800&family=JetBrains+Mono:ital,wght@0,100..800&display=swap");
@import "./multi-chain.scss";
@import "./nft-animation.scss";
@import "./theme-toggle.module.scss";
@import "./bottomsheet.scss";

// Adding notosans for Pricing page
// @font-face {
//   font-family: "Notosans Medium";
//   src: url("../assets/fonts/NotoSans-Medium.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Notosans Regular";
//   src: url("../assets/fonts/NotoSans-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Notosans Light";
//   src: url("../assets/fonts/NotoSans-Light.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Notosans Thin";
//   src: url("../assets/fonts/NotoSans-Thin.ttf") format("truetype");
// }

@font-face {
  font-family: "Gilroy Regular";
  src: url("/fonts/Gilroy-Regular.woff2");
}

@font-face {
  font-family: "Gilroy Medium";
  src: url("/fonts/Gilroy-Medium.woff2");
}

@font-face {
  font-family: "Gilroy Semibold";
  src: url("/fonts/Gilroy-Semibold.woff2");
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("/fonts/Gilroy-Bold.woff2");
}

@font-face {
  font-family: "Gilroy ExtraBold";
  src: url("/fonts/Gilroy-ExtraBold.woff2");
}

@font-face {
  font-family: "Gilroy Thin";
  src: url("/fonts/Gilroy-Thin.woff2");
}

@font-face {
  font-family: "Gilroy UltraLight";
  src: url("/fonts/Gilroy-UltraLight.woff2");
}

html {
  scroll-behavior: smooth;
  background-color: var(--bg-color) !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Gilroy Regular;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  .slick-prev,
  .slick-next {
    font-size: 15px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }

  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slick-dots.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background: #dee9ff;
    border: 1px solid #004be9;
    text-indent: -9999px;
  }

  .slick-dots.slick-thumb li.slick-active {
    background: #0052ff;
    width: 24px;
  }
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  .slick-prev,
  .slick-next {
    font-size: 15px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }

  .slick-dots-pricing {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots-pricing.slick-thumb {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
  }

  .slick-dots-pricing.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background: rgba(255, 255, 255, 0.2);
    text-indent: -9999px;
  }

  .slick-dots-pricing.slick-thumb li.slick-active {
    background: #2c8dff;
    width: 24px;
  }
}

[data-theme="light"] {
  --primary-theme-text: #001b55;
  // --bg-color: #141416;
  --bg-color: #ffffff;
  --navbar-gradient: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.55),
    rgba(255, 255, 255, 0.25)
  );
  --background-gradient: linear-gradient(
    90.59deg,
    #ffffff 0.51%,
    #c6d2ff 99.57%
  );
  --landing-first: #ffffff;
  --landing-second: #f8fafe;
  --footer-bg: #e5edfd;
  --footer-bottom: #ceddfb;
  --dropdown-nav-text: #001b55;
  --stats-card-bg: linear-gradient(90.59deg, #ffffff 0.51%, #f8fafe 99.57%);
  --border-gradient: linear-gradient(to right, #004be9, #ffffff) 1;
  --theme-toggle: black;
  --tweet-card-gradient: linear-gradient(to right, #004be9, #ffffff) 1;
  --tweet-card-bg: #ffffff;
  --section-theme-bg: #f8fafe;
  --disclosure-open-bg: #ffffff;
  --disclosure-close-bg: #ffffff;
  --made-easy-card-bg: #ffffff;
  --call-to-action-bg: linear-gradient(90.59deg, #ffffff 0.51%, #c6d2ff 99.57%);
  --disclosure-border: linear-gradient(to right, #004be9, #004be9) 1;
  --disclosure-shadow: 5px 5px 5px 0px #93aafd33;
  --disclosure-border-close: 1px solid rgba(0, 75, 233, 0.2);
  --tool-tip-bg: #fff;
  --tool-tip-text: #000;
  --tool-tip-shadow: rgb(0 0 0 / 15%) 0px 0px 4px 2px;

  // pricing page
  --table-bg: #fff;
  --table-desc: #02174d;
  --primary-bg: linear-gradient(
    180deg,
    rgba(77, 94, 255, 0.126) 0%,
    rgba(255, 255, 255, 0) 93.17%
  );
  --investor-bg: linear-gradient(
    180deg,
    rgba(0, 71, 255, 0.04) 29.73%,
    rgba(3, 27, 89, 0) 100%
  );
  --card-first: #e9efff;
  --card-sec: linear-gradient(
      245.22deg,
      rgba(7, 70, 233, 0.3) 2.04%,
      rgba(10, 78, 211, 0.0919531) 45.02%,
      rgba(0, 0, 0, 0) 99.13%
    ),
    #111728;
  --journey-area-bg: linear-gradient(
      127.77deg,
      rgba(0, 78, 241, 0.14) 13.58%,
      rgba(1, 76, 234, 0) 58.54%
    ),
    #161e34;
  --journey-card: rgba(255, 255, 255, 0.03);
  --feature-color: #000000;
  --feature-heading-bg: linear-gradient(
      180deg,
      rgba(0, 118, 226, 0.12) 0%,
      rgba(5, 0, 226, 0) 100%
    ),
    #fbfaff;
  --tooltip-bg: #eff0f6;
  --tooltip-text: #02174d;
  --compute-bg: linear-gradient(
      180deg,
      rgba(0, 37, 169, 0.14) 0%,
      rgba(0, 56, 255, 0) 59.39%
    ),
    #121828;
  --price-heading: #170f49;
}

[data-theme="dark"] {
  background-color: #000000;
  --primary-theme-text: #ffffff;
  --bg-color: #141416;
  --navbar-gradient: linear-gradient(
    to bottom,
    rgba(4, 4, 5, 0.55),
    rgba(9, 8, 20, 0.25)
  );
  --background-gradient: linear-gradient(
    90.59deg,
    #000000 0.51%,
    #001023 99.57%
  );
  --landing-first: #000000;
  --landing-second: #121212;
  --footer-bg: rgb(14, 14, 14);
  --footer-bottom: #000000;
  --stats-card-bg: linear-gradient(90.59deg, #121212 0.51%, #000000 99.57%);
  --dropdown-nav-text: #ffffff;
  --theme-toggle: white;
  --border-gradient: linear-gradient(to right, #004be9, rgba(0, 27, 85, 1)) 1;
  --tweet-card-gradient: linear-gradient(to right, #004be9, #30455c) 1;
  --tweet-card-bg: #121212;
  --section-theme-bg: #072144;
  --disclosure-open-bg: linear-gradient(
    90.59deg,
    #121212 0.51%,
    #000000 99.57%
  );
  --disclosure-close-bg: #000000;
  --made-easy-card-bg: #031c3c;
  --call-to-action-bg: linear-gradient(90.59deg, #000000 0.51%, #001023 99.57%);
  --disclosure-border: linear-gradient(to right, #004be9, rgba(0, 75, 233, 0)) 1;
  --disclosure-shadow: 0px 0px 0px 0px;
  --disclosure-border-close: 1px solid rgba(51, 102, 220, 0.5);
  --tool-tip-bg: #000;
  --tool-tip-text: #fff;
  --tool-tip-shadow: rgb(255 255 255 / 43%) 0px 0px 4px 2px;

  // pricing page
  --table-bg: #121320;
  --table-desc: #fff;
  --primary-bg: linear-gradient(
    180deg,
    rgba(0, 26, 255, 0.126) 0%,
    rgba(0, 26, 255, 0) 93.17%
  );
  --investor-bg: linear-gradient(
    180deg,
    rgba(0, 71, 255, 0.06) 29.73%,
    rgba(3, 27, 89, 0) 100%
  );
  --card-first: rgba(80, 98, 190, 0.11);
  --card-sec: linear-gradient(
      245.22deg,
      rgba(33, 86, 224, 0.11) 2.04%,
      rgba(10, 78, 211, 0.0613021) 45.02%,
      rgba(0, 0, 0, 0) 99.13%
    ),
    #111728;
  --journey-area-bg: linear-gradient(
      180deg,
      rgba(0, 37, 169, 0.14) 0%,
      rgba(0, 56, 255, 0) 59.39%
    ),
    rgba(2, 18, 133, 0.05);
  --journey-card: linear-gradient(
      180deg,
      rgba(0, 71, 255, 0) 0%,
      rgba(0, 71, 255, 0.05) 100%
    ),
    rgba(255, 255, 255, 0.03);
  --feature-color: #c7ccea;
  --feature-heading-bg: rgba(16, 21, 43, 0.76);
  --tooltip-bg: #1a1f34;
  --tooltip-text: #a7aed8;
  --compute-bg: linear-gradient(
      180deg,
      rgba(0, 37, 169, 0.14) 0%,
      rgba(0, 56, 255, 0) 59.39%
    ),
    rgba(2, 18, 133, 0.05);
  --price-heading: #fff;

  // new-ui-design
  --primary-hover-btn: #0046cc;
  --report-background: linear-gradient(
    180.14deg,
    rgba(20, 20, 22, 0.4) 9.99%,
    rgba(0, 87, 255, 0.45) 53.16%,
    rgba(20, 20, 22, 0.37) 92.05%
  );
  --report--span: linear-gradient(180deg, #8247ff 0%, #0057ff 100%);
}

.dotted-bg {
  background: url("../assets/images/Backgrounds/dot-background.svg") no-repeat;
  background-size: cover;
  background-position: -110px 20px;
  height: 37.125rem;
}

.dotted-bg-twice {
  background: url("../public/dot-bg-framework.svg");
  background-repeat: no-repeat;
  // background-size: cover;
  background-position: center center;

  background-size: inherit;
}

.step-container {
  padding: 50px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 50px 50px 40px #004be933, inset 3px 3px 10px #2cdaff44;
  height: 350px;
  width: 500px;
  position: absolute;
  top: 25%;
  right: 10%;
  transform-style: preserve-3d;
  /* transform: perspective(700px) rotate3d(0, -45, 1, 30deg); */
  /* transition: all 0.6s ease; */
}

.step-container .float-logo {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 50px 50px 40px #004be933, inset 3px 3px 10px #2cdaff44;
  height: 80px;
  width: 80px;
  position: absolute;
  top: -10%;
  left: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.step-container .float-logo > div > img {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.step-container .float-logo > div {
  height: 50%;
  width: 50%;
  /* border: 1px solid green; */
}

.step-container .step1 {
  text-align: center;
  overflow: hidden;
  opacity: 1;
}

.step-container .step1 .title1 {
  position: absolute;
  opacity: 0;
  top: 20%;
  left: 35%;
}

.step-container .step1 .select-one {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 50%;
}

.step1 .select-one .github,
.step-container .step1 .select-one .gitlab {
  padding: 30px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 10px 10px 20px #004be933, inset 3px 3px 10px #2cdaff44;
}

.step-container .github {
  background: #004be9;
  color: #ffffff;
  opacity: 1;
}

.step-container .gitlab {
  background: #ffffff;
  color: #001023;
  border: 1px solid #004be9;
  opacity: 1;
}

.step-container .step1 .step-1-btn {
  position: absolute;
  bottom: 10%;
  left: 35%;
  background: #004be9;
  color: #ffffff;
  box-shadow: 0px 5px 10px rgba(26, 122, 246, 0.5),
    inset 3px 3px 20px rgba(44, 218, 255, 0.5);
  padding: 10px 30px;
  border-radius: 10px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.6s ease;
  font-weight: bold;
  cursor: pointer;
  z-index: 0;
}

.step-container .step1 .step-1-btn:hover {
  background: #ffffff;
  border: 1px solid #004be9;
  box-shadow: inset 3px 3px 20px #2cdaff99, 0px 5px 10px #1a7af699,
    5px 1px 0px #004db900;
  color: #004be9;
  bottom: 9%;
  left: 35%;
}

.step-container .right-icons {
  height: auto;
  width: auto;
  position: absolute;
  display: grid;
  top: 10%;
  right: -5%;
}

.step-container .right-icons > div {
  border: 1px solid green;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 10px 10px 10px #004be933, inset 3px 3px 10px #2cdaff44;
  padding: 15px;
  margin: 10px 0;
  width: 65px;
  border: 1px solid transparent;
}

.step-container .right-icons > div > span {
  overflow: unset !important;
}

.step-container .right-icons .connect {
  position: absolute;
  right: -50%;
  top: -30px;
  opacity: 0;
}

.step-container .right-icons .setting {
  position: absolute;
  right: -50%;
  top: 50px;
  opacity: 0;
}

.step-container .right-icons .rocket {
  height: 50% !important;
  width: 50% !important;
  top: 130px;
  opacity: 0;
}

.step-container .step2 .title2 {
  text-align: center;
  position: absolute;
  top: 12%;
  left: 30%;
  color: #ffffff;
}

.step-container .line1,
.step-container .line2,
.step-container .line3,
.step-container .line4 {
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  position: absolute;
}

.step-container .line1 {
  right: 10%;
  top: 25%;
  width: 80%;
  opacity: 1;
}

.step-container .line2 {
  right: 10%;
  top: 36%;
  width: 60%;
  opacity: 0.7;
}

.step-container .line3 {
  right: 10%;
  top: 47%;
  width: 60%;
  opacity: 0.5;
}

.step-container .line4 {
  right: 10%;
  top: 58%;
  width: 60%;
  opacity: 0.3;
}

.step-container .step2 .step-2-btn {
  position: absolute;
  left: 35%;
  background: #ffffff;
  color: #004be9;
  box-shadow: 50px 50px 40px rgba(0, 75, 233, 0.27),
    0px 5px 10px rgba(26, 122, 246, 0.5),
    inset 3px 3px 10px rgba(44, 218, 255, 0.2);
  padding: 10px 30px;
  border-radius: 10px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.6s ease;
  font-weight: bold;
  opacity: 0;
  cursor: pointer;
}

.step-container .step2 .step-2-btn:hover {
  background: #004be9;
  border: 1px solid #ffffff;
  box-shadow: inset 3px 3px 20px #2cdaff99, -0px -5px 10px #1a7af699,
    -5px -1px 0px #004db900;
  color: #ffffff;
  left: 35%;
}

.step-container .step3 .deploy-rocket {
  position: absolute;
  top: 10%;
  left: 32%;
  height: 60%;
  width: auto;
  opacity: 0;
}

.step-container .step3 .title3 {
  position: absolute;
  bottom: 0%;
  left: 30%;
}

.title1,
.title3,
.title4 {
  font-weight: 700;
  color: #001023 !important;
}

.step-container .step1 {
  z-index: 1;
}

.step-container .step2 {
  z-index: 2;
}

.step-container .step3 {
  z-index: 3;
  height: 80%;
}

.step-container .step3 .title4 {
  position: absolute;
  left: 30%;
  top: 30%;
  opacity: 1;
}

.step-container .step3 .step-3-btn {
  position: absolute;
  top: 60%;
  left: 10%;
  padding: 10px 15px;
  width: 80%;
  color: #004be9;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.5s ease;
  opacity: 1;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #004be9;
  box-sizing: border-box;
  border-radius: 10px;
}

.step-container .step3 .step-3-btn:hover {
  border: 1px solid #004be9;
  box-shadow: 0px 5px 10px rgba(26, 122, 246, 0.5),
    inset 3px 3px 20px rgba(44, 218, 255, 0.5);
}

.step-container .step3 .step-3-btn > .copy-wrapper {
  position: absolute;
  right: 7%;
  top: 25%;
}

.rocket-wrapper {
  height: 50%;
  width: 50%;
  position: absolute;
  top: 10%;
  left: 25%;
}

.extra-light-blue-bg {
  background: var(--section-theme-bg);
}

.light-blue-bg {
  background: rgba(0, 75, 233, 0.1);
}

/* ul li {
  list-style-image: url(../public/tick.svg);
} */
@media (max-width: 1024px) {
  .dotted-bg-twice {
    background: url("../assets/images/Backgrounds/dot-background.svg") no-repeat;
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center center;

    background-size: cover;
  }
}

.w-50 {
  width: 50%;
}

.m-auto {
  margin: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.opacity-full {
  opacity: 1 !important;
}

.w-90 {
  width: 90%;
}

.negative-margin-top {
  margin-top: -15rem;

  @media screen and (max-width: 767px) {
    margin-top: 0rem;
  }
}

.footer-image-container {
  opacity: 0.4;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: auto !important;

  @media screen and (max-width: 726px) {
    display: none !important;
  }
}

.dark_newsletter_bg {
  background: linear-gradient(
    89.79deg,
    rgba(60, 112, 221, 0.1) 8.49%,
    rgba(44, 218, 255, 0.1) 97.1%
  ) !important;
}

.list__header_gradient {
  background: linear-gradient(90.71deg, #2cdaff 0%, #004be9 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient_underline {
  background: linear-gradient(89.79deg, #004be9 8.49%, #2cdaff 97.1%);
  width: 30px;
  height: 3px;
  border-radius: 20px;
}

.gradient_underline_reverse {
  background: linear-gradient(89.79deg, #2cdaff 8.49%, #004be9 97.1%);
  width: 30px;
  height: 3px;
  border-radius: 20px;
}

.modal-padding {
  padding: 20px;
}

.modal-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #072144;
}

#headlessui-dialog-1 {
  z-index: 99999;
  position: absolute;
}

.blue-border {
  border: 1px solid #c6d2ff;
}

.font-primary {
  color: var(--primary-theme-text);
}

.mobile-area {
  display: none !important;

  @media screen and (max-width: 726px) {
    display: block !important;
  }
}

// classes for mdx
.image-box-shadow {
  box-shadow: 0px 0px 1rem rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-top: 1rem;

  span {
    border-radius: 10px;
  }
}

.image-box {
  margin-top: 1rem;
}

.havingTooltip {
  cursor: pointer !important;
}

.slick-slide {
  div {
    margin-left: 10px;
    margin-right: 10px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.background_div {
  background: linear-gradient(180deg, rgba(21, 21, 23, 0) 0%, #151517 50%);
}

.background_gradient_lines {
  background-image: url("../assets/line-gradient.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.text_color_gradient {
  color: transparent;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #897fff -0.54deg,
      #92b7fd 138.99deg,
      #a175ff 258.75deg,
      #897fff 359.46deg,
      #92b7fd 498.99deg
    ),
    linear-gradient(0deg, #fcfcfd, #fcfcfd);
  background-clip: text;
}

.testimonial_background {
  background-image: url("../assets/testimonial-background.svg");
  background-color: #141416;
  background-repeat: no-repeat;
  background-position: left;
  width: 100% !important;
}

.tech_stack_circle_background {
  background-image: url("../assets/tech-stack-circle-bg.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.draw_line {
  stroke-dasharray: 30 250;
  stroke-dashoffset: 200;
  animation: dash 70s linear forwards infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 10000;
  }
}

.forward_fizz_line {
  stroke-dasharray: 30 250;
  stroke-dashoffset: 200;
  animation: dash2 70s linear forwards infinite;
}

@keyframes dash2 {
  0% {
    stroke-dashoffset: 106; /* Full length (invisible) */
  }
  50% {
    stroke-dashoffset: 0; /* Fully visible (right-to-left) */
  }
  75% {
    stroke-dashoffset: 0; /* Remains fully visible during pause */
  }
  100% {
    stroke-dashoffset: 106; /* Back to start (invisible) */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate_animation {
  transform-origin: 34% center;
  animation: rotate 2.5s linear forwards infinite;
}

.rotate_phone_animation {
  transform-origin: center 40%;
  animation: rotate 2.5s linear forwards infinite;
}

.reverse_line_top_globe {
  stroke-dasharray: 10 250;
  stroke-dashoffset: 650;
  animation: back 70s linear forwards infinite;
}
.reverse_line_top_globe_red {
  stroke-dasharray: 30 250;
  stroke-dashoffset: 650;
  animation: back 70s linear forwards infinite;
}

.reverse_line {
  stroke-dasharray: 25 250;
  stroke-dashoffset: 200;
  animation: back 70s linear forwards infinite;
}

.reverse_line_vertical_image {
  stroke-dasharray: 30 250;
  stroke-dashoffset: 200;
  animation: backVerticalImage 10s linear forwards infinite;
}

.forward_line {
  stroke-dasharray: 25 250;
  stroke-dashoffset: 200;
  animation: back 70s linear backwards infinite;
}

@keyframes backVerticalImage {
  from {
    stroke-dashoffset: 650;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes back {
  from {
    stroke-dashoffset: 10000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.dots_bg {
  background-image: url("./../assets/card-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ellipse_gradient_bg {
  background-image: url("../assets/ellipseGradient.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.circle_gradient_bg {
  background-image: url("../assets/circleGradient.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.top_bg_gradient {
  background-repeat: no-repeat;
  border-radius: 100%;
  height: 210px;
  width: 210px;
  background-color: #0058ff;
  filter: blur(177px);
}

.side_bg_gradient {
  background-repeat: no-repeat;
  width: 367px;
  height: 367px;
  flex-shrink: 0;
  border-radius: 367px;
  opacity: 0.4;
  background: linear-gradient(180deg, #0057ff 0%, #8247ff 100%);
  filter: blur(210px);
}

// @media (max-width: 640px) {
//   .top_bg_gradient {
//     height: 160px;
//     width: 160px;
//   }
// }

.ellipse_gradient {
  width: 100vw;
  height: 319px !important;
  filter: blur(210px) !important;
}

@media (max-width: 640px) {
  .ellipse_gradient {
    height: 210px;
  }
}

.border_layout {
  position: relative;
  margin: 0 20px 0;
  border: 1px solid #22222a;
  margin-top: -1px;
}

.border_layout:last-of-type {
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .border_layout {
    margin: 0;
    border: none;
    margin-top: 0;
  }

  .border_layout:last-of-type {
    margin-bottom: 0px;
  }
}

.border_layout::before {
  position: absolute;
  width: 12px;
  height: 12px;
  content: "";
  left: 0;
  translate: -50% -50%;
  background-image: url("./../assets/border-plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 640px) {
    background-image: none;
  }
}

.border_layout::after {
  position: absolute;
  width: 12px;
  height: 12px;
  content: "";
  top: 0;
  right: 0;
  translate: 50% -50%;
  background-image: url("./../assets/border-plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 640px) {
    background-image: none;
  }
}

.border_layout_overflow {
  margin: 0 20px 0;
  border: 1px solid #22222a;
  margin-top: -1px;
  overflow-x: visible !important;
}

@media (max-width: 640px) {
  .border_layout_overflow {
    margin: 0;
    border: none;
    margin-top: 0;
  }

  .border_layout_overflow:last-of-type {
    margin-bottom: 0px;
  }
}

.border_layout_overflow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  content: "";
  left: 0;
  translate: -50% -50%;
  background-image: url("./../assets/border-plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 640px) {
    background-image: none;
  }
}

.border_layout_overflow::after {
  position: absolute;
  width: 12px;
  height: 12px;
  content: "";
  top: 0;
  right: 0;
  translate: 50% -50%;
  background-image: url("./../assets/border-plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 640px) {
    background-image: none;
  }
}

.table_row:last-child {
  td {
    border: none;
  }
}

.slick-slide {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
  opacity: 1;
}

.slick-slide:not(.slick-current) {
  opacity: 0.5;
}

.slick-slide.slick-current .testimonial_background {
  border-radius: 16px;
  border: 1px solid #0057ff;
  // -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  // -webkit-mask-composite: xor;
  // mask-composite: exclude;

  // border: 1px solid linear-gradient(#09609d, #6943ff);
  // border-radius: 30px;
  // // background: transparent;
  // background-origin: border-box;
  // background-clip: content-box, border-box;
}

.faq_disclosure_btn {
  border-radius: 5.061px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.08);
}
